.mainContainer{
    padding: 20px;
}

.MuiDataGrid-cell {
    font-size: 10px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
    font-size: 10px;
}

.MuiIconButton-label {
    font-size: 10px;
}
.MuiDataGrid-footerContainer{
    height: 30px;
}

.MuiDataGrid-cell{
    font-size: 11.5px;
}

.highlight {
    color: red;
}