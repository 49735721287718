.mainDashbaordContainer{
    padding: 0px;
    height: 100%;
}

.MuiDataGrid-cell {
    font-size: 10px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
    font-size: 10px;
}

.MuiIconButton-label {
    font-size: 10px;
}
.fullHeight{
    height: 100%;
}

.halfHeight{
    height: 50%;
}

.settingsHeaderContainer {
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settingsButton {
    position: absolute;
    right: 10px;
    margin-bottom: 10px;
}